// Author: Çağrı Erdem, 2024
// Description: Main script for the MishMash pitch on 240925

let isToastVisible = false;

function submitWords(data, local = false) { 
    let data_url = ``;

    if (local === true) {
        data_url = `http://127.0.0.1:5005/submit`; 
    } else {
        data_url = `https://cerdemo.com/submit`;
    }

    console.log("Sending request to:", data_url);

    const payload = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            words: data
        })
    };

    fetch(data_url, payload)
    .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();  // Assume JSON response
    })
    .then(data => {
        console.log("Server response:", data);
    })
    .catch(error => {
        console.error("Error:", error);
        alert("There was an error submitting your entry. Please try again.");
    });
}


function showSuccessToast(message) {
    const toast = document.getElementById('toast-success');
    if (isToastVisible) return;  // Check if the toast is already visible

    toast.innerHTML = message;  // Set the dynamic message
    toast.style.display = 'block';
    isToastVisible = true;  // Set the flag to indicate the toast is visible

    setTimeout(() => {
        toast.style.display = 'none';
        isToastVisible = false;  // Reset the flag after the toast is hidden TODO: this does not work
    }, 6000);
}

function showWarning() {
    const warning = document.getElementById('warning');
    warning.style.display = 'block';

    setTimeout(() => { 
        warning.style.display = 'none';
    }, 4000);
}

// Wait until loaded DOM
document.addEventListener('DOMContentLoaded', function () {
    const dm1 = document.getElementById('dm1');
    const dm2 = document.getElementById('dm2');
    const form = document.querySelector('form');

    function updateDropdowns() {
        const selectedValueDm1 = dm1.value;
        const selectedValueDm2 = dm2.value;

        Array.from(dm1.options).forEach(option => option.disabled = false);
        Array.from(dm2.options).forEach(option => option.disabled = false);

        if (selectedValueDm1) {
            Array.from(dm2.options).forEach(option => {
                if (option.value === selectedValueDm1) {
                    option.disabled = true;
                }
            });
        }

        if (selectedValueDm2) {
            Array.from(dm1.options).forEach(option => {
                if (option.value === selectedValueDm2) {
                    option.disabled = true;
                }
            });
        }
    }

    // Attach event listeners to both menus
    dm1.addEventListener('change', updateDropdowns);
    dm2.addEventListener('change', updateDropdowns);

    // Adding form submit handler
    form.addEventListener('submit', function (event) {
        event.preventDefault();  // Prevent default form submission

        const selectedDm1 = dm1.value;
        const selectedDm2 = dm2.value;

        // Check if the user selected both options
        if (!selectedDm1 || !selectedDm2) {  // If either menu is empty
            showWarning();  // Show the warning
            return;  // Do not proceed with submission
        }

        // Hide the warning if everything is selected
        const warning = document.getElementById('warning');
        warning.style.display = 'none';

        const data = { dm1: selectedDm1, dm2: selectedDm2 };
        submitWords(data);
        const dynamicMessage = `You think: "AI must be ${selectedDm1} to be ${selectedDm2}." Great!`;
        showSuccessToast(dynamicMessage);  
    });
});
